<mat-card appearance="outlined" class="main-panel-style">
    <mat-card-content *ngIf="selectedIntegration && settings && settings.length > 0">
        <app-save-reset-buttons (onResetClick)="formToInitialValues()"
                                (onSaveClick)="onSubmit()"
                                [formButtonsDisabled]="formButtonsDisabled"></app-save-reset-buttons>
        <form (keydown.enter)="$event.stopPropagation()" [formGroup]="settingsFormGroup">
            <div>
                <button color="primary" mat-raised-button (click)="accordion.openAll()">Expand All</button>
                <button color="primary" mat-raised-button style="margin-left: 3pt;" (click)="accordion.closeAll()">Collapse All</button>
            </div>
            <mat-accordion multi>
                <div *ngFor="let section of sections;">
                    <mat-expansion-panel expanded="true">
                        <mat-expansion-panel-header style="font-size: large">
                            <mat-panel-title>
                                {{section}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngFor="let element of settingsForSection(section);">
                            <app-info-item [setting]="element"
                                           [settingsArrayFormGroup]="settingsFormGroup"
                                           id="{{element.name}}"></app-info-item>
                        </div>
                    </mat-expansion-panel>
                </div>
            </mat-accordion>
        </form>
        <app-save-reset-buttons (onResetClick)="formToInitialValues()"
                                (onSaveClick)="onSubmit()"
                                [formButtonsDisabled]="formButtonsDisabled"></app-save-reset-buttons>
    </mat-card-content>
</mat-card>
