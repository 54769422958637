import {Directive, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import {Setting} from '../../../core/models/setting';
import {UntypedFormGroup} from '@angular/forms';
import {SettingDTO} from '../../../core/dto/setting-dto';
import {TabIdentifier} from '../../../core/enums/tab-identifier';
import {SettingService} from '../../../core/services/setting/setting.service';
import {SettingFilterService} from '../../../core/services/setting/setting-filter.service';
import {DynamicFormService} from '../../../core/util/dynamic-form/dynamic-form.service';
import {LegalFirmService} from '../../../core/services/legal-firm/legal-firm.service';
import {MatAccordion} from '@angular/material/expansion';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ErrorComponent} from './error.component';

@Directive()
export abstract class BaseTabComponent implements OnInit {

    @Input()
    refreshSettingsEvent: EventEmitter<Setting[]>;

    settings: Setting[];
    settingsFormGroup: UntypedFormGroup;
    changedSettings: SettingDTO[] = [];
    formButtonsDisabled = true;

    sections: String[] = [];
    @ViewChild(MatAccordion) accordion: MatAccordion;

    protected constructor(readonly tabId: TabIdentifier,
                          protected settingService: SettingService,
                          protected settingFilterService: SettingFilterService,
                          protected legalFirmService: LegalFirmService,
                          protected dynamicFormService: DynamicFormService,
                          protected snackBar: MatSnackBar) {
    }

    ngOnInit(): void {
        if (!this.refreshSettingsEvent) {
            throw new Error('refreshSettingsEvent input is required');
        }
        this.refreshSettingsEvent.subscribe((settings: Setting[]) => {
            this.refreshFormGroup(settings);
        })
    }

    onSubmit(): void {
        if (this.legalFirmService.legalFirmId != null) {
            this.detectErrors()
            if (this.settingsFormGroup.invalid) {
                return;
            }
            this.detectChangedElements();
            this.settingService.saveSettings(this.changedSettings)
                .subscribe(() => {
                    this.changedSettings = [];
                    this.enableFormButtons();
                    this.legalFirmService.refreshLegalFirmSettingsEvent.emit();
                });
        }
    }

    disableFormButtons(): void {
        if (this.legalFirmService.legalFirmId != null) {
            this.formButtonsDisabled = false;
        }
    }

    enableFormButtons(): void {
        this.formButtonsDisabled = true;
    }

    detectErrors() {
        const errors: string[] = [];
        for (const settingName of Object.keys(this.settingsFormGroup.controls)) {
            this.settingsFormGroup.controls[settingName].markAsTouched();
            const setting = this.settings.find(e => e.name === settingName);
            if (this.settingsFormGroup.controls[settingName].invalid) {
                setting.invalid = true;
                errors.push(setting.section ? setting.section + '/' + settingName : settingName)
            } else {
                setting.invalid = false;
            }
        }
        if (errors.length === 0) {
            return
        }
        this.snackBar.openFromComponent(ErrorComponent, {
            data: 'Validation Error for: ' + errors.join(','),
            duration: 5000
        })
    }

    detectChangedElements() {
        const initialValuesFormGroup: UntypedFormGroup = this.dynamicFormService.toFormGroup(this.settings);
        for (const settingName of Object.keys(this.settingsFormGroup.controls)) {
            this.detectChangedElement(settingName, initialValuesFormGroup);
        }
    }

    detectChangedElement(settingName: string, initialValuesFormGroup: UntypedFormGroup) {
        const setting = this.settings.find(e => e.name === settingName);
        const currentElementValue = this.settingsFormGroup.controls[settingName].value;
        const initialElementValue = initialValuesFormGroup.controls[settingName].value;
        if (initialElementValue === currentElementValue) {
            return;
        }
        const element = new SettingDTO(this.legalFirmService.legalFirmId, setting.identifier, currentElementValue);
        this.addChangedElement(element);
    }

    refreshFormGroup(settings: Setting[]): void {
        this.settings = this.settingFilterService.filterByTabId(settings, this.tabId);
        this.refreshSections()
        this.settingsFormGroup = this.dynamicFormService.toFormGroup(this.settings);
        this.settingsFormGroup.valueChanges.subscribe(() => this.disableFormButtons());
    }


    formToInitialValues() {
        this.dynamicFormService.formToInitialValues(this.settings, this.settingsFormGroup);
        this.enableFormButtons();
    }

    addChangedElement(element: SettingDTO) {
        const changedElementIndex = this.changedSettings.findIndex(e => e.setting_id === element.setting_id);
        if (changedElementIndex === -1) {
            this.changedSettings.push(element);
            return;
        }
        this.changedSettings[changedElementIndex] = element;
    }

    settingsForSection(section: String) {
        return this.settings.filter(e => {
            if (section === 'General') {
                return e.section === undefined;
            } else {
                return e.section === section;
            }
        });
    }

    refreshSections(): void {
        this.sections = []
        if (this.settings === undefined) {
            return
        }
        for (const setting of this.settings) {
            let section: string;
            if (typeof setting.section !== 'undefined') {
                section = setting.section;
            } else {
                section = 'General';
            }
            if (this.sections.indexOf(section) === -1) {
                this.sections.push(section);
            }
        }
    }
}
