<app-header></app-header>
<div class="toolbar-container">
    <mat-toolbar-row class="button-row">
        <button color="primary" id="back" mat-mini-fab mat-raised-button routerLink="/">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <button (click)="openDialog()" color="primary" id="add-metadata" mat-mini-fab mat-raised-button>
            <mat-icon>add</mat-icon>
        </button>
    </mat-toolbar-row>
</div>

<div class="table-container">

    <table [dataSource]="dataSource" class="mat-elevation-z8 table-style" mat-table matSort>
        <caption class="caption-style">Settings metadata table</caption>

        <ng-container matColumnDef="name">
            <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col"> Name</th>
            <td *matCellDef="let element" mat-cell> {{element.name}}</td>
        </ng-container>

        <ng-container matColumnDef="identifier">
            <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col"> Identifier</th>
            <td *matCellDef="let element" mat-cell> {{element.identifier}}</td>
        </ng-container>

        <ng-container matColumnDef="active">
            <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col"> Active</th>
            <td *matCellDef="let element" mat-cell>
                <mat-checkbox [disabled]="true" [checked]="element.active"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="dataType">
            <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col"> Data Type</th>
            <td *matCellDef="let element" mat-cell> {{element.dataType}}</td>
        </ng-container>

        <ng-container matColumnDef="integrationId">
            <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col">Integration</th>
            <td *matCellDef="let element"
                mat-cell> {{ element.displayData && element.displayData.displayIdentifiers
              ? getIntegrationsAsString(element.displayData.displayIdentifiers.integrationIds)
              : ''}}</td>
        </ng-container>

        <ng-container matColumnDef="tabId">
            <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col">Tab identifier</th>
            <td *matCellDef="let element"
                mat-cell> {{ element.displayData && element.displayData.displayIdentifiers ? element.displayData.displayIdentifiers.tabId : ''}}</td>
        </ng-container>

        <ng-container matColumnDef="tooltipLocation">
            <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col">Tooltip Location</th>
            <td *matCellDef="let element" mat-cell> {{element.tooltipLocation}}</td>
        </ng-container>

        <!-- Star Column -->
        <ng-container matColumnDef="star" stickyEnd>
            <th *matHeaderCellDef mat-header-cell scope="col"></th>
            <td *matCellDef="let element" mat-cell>
                <button (click)="openDialog(element)" mat-icon-button>
                    <mat-icon>more_vert</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>

</div>
