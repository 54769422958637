import {Component, NgZone, OnInit} from '@angular/core';
import {AuthService} from '../../core/services/auth/auth.service';
import {interval} from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    constructor(private authService: AuthService, private ngZone: NgZone) {
    }

    ngOnInit(): void {
        this.ngZone.runOutsideAngular(() => {
            interval(15000).subscribe(() => {
                this.authService.verifyAuth()
            })
        })
    }

    logout() {
        this.authService.logout();
    }
}
