import {Component, Inject} from "@angular/core";
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
    selector: 'your-snackbar',
    template: '<span class="error">{{ data }}</span>',
    styles: ['.error { color: red; font-weight: bold;} ']
})
export class ErrorComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) {
    }
}