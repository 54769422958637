import {Injectable} from '@angular/core';
import {SettingDTO} from '../../dto/setting-dto';
import {forkJoin, Observable} from 'rxjs';
import {HttpWrapperService} from '../http/http-wrapper.service';
import {map} from 'rxjs/operators';
import {HttpHeaders} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class SettingService {

    constructor(private httpWrapperService: HttpWrapperService) {
    }

    getAllSettings(legalFirmId: number): Observable<SettingDTO[]> {
        return this.httpWrapperService
            .get<SettingDTO[]>(`setting/${legalFirmId}`);
    }

    saveNewSetting(settingDTO: SettingDTO): Observable<SettingDTO> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.httpWrapperService.put<SettingDTO>(`setting/${settingDTO.firm_id}/${settingDTO.setting_id}`,
            settingDTO, headers)
            .pipe(map(() => settingDTO));
    }

    saveSettings(array: SettingDTO[]): Observable<SettingDTO[]> {
        return forkJoin(
            array.map(setting => this.saveNewSetting(setting))
        );
    }

    getSettingValue(settingDTO: SettingDTO): Observable<SettingDTO> {
        return this.httpWrapperService.get(`setting/${settingDTO.firm_id}/${settingDTO.setting_id}`);
    }
}