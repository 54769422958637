import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../core/services/auth/auth.service';

@Component({
    selector: 'app-login-callback',
    templateUrl: './login-callback.component.html',
    styleUrls: ['./login-callback.component.css']
})
export class LoginCallbackComponent implements OnInit {

    constructor(private route: ActivatedRoute,
                private authService: AuthService,
                private router: Router) {
    }

    ngOnInit() {
        this.handleToken();
    }

    handleToken() {
        this.route.fragment.subscribe(params => {
            if (params) {
                const parameters = params.split('&');
                const idToken = parameters[0].split('=')[1];
                this.authService.login(idToken);
            } else {
                this.router.navigate([this.authService.getLoginUrl()]);
            }
        });
    }
}
