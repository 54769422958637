import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {AuthService} from '../auth/auth.service';
import {Observable, throwError} from 'rxjs';
import {ToasterService} from '../toastr/toaster.service';
import {GlobalSpinnerService} from '../global-spinner/global-spinner.service';
import {catchError, finalize} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthHandler extends HttpHandler {

    private totalRequests = 0;

    constructor(
        private readonly authService: AuthService,
        private readonly next: HttpHandler,
        private toasterService: ToasterService,
        private globalSpinnerService: GlobalSpinnerService) {
        super();
    }

    /** @override */ handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
        const clone = req.clone({
            setHeaders: {
                Authorization: this.authService.getToken()
            }
        });
        this.totalRequests++;
        this.globalSpinnerService.enableSpinner();

        return this.next.handle(clone)
            .pipe(
                catchError(err => {
                    this.globalSpinnerService.disableSpinner();
                    return this.handleError(err);
                }),
                finalize(() => {
                    this.totalRequests--;
                    if (this.totalRequests === 0) {
                        this.globalSpinnerService.disableSpinner();
                    }
                })
            );
    }

    handleError(res: HttpErrorResponse): Observable<never> {
        if (res.status === 401 || res.status === 403) {
            this.authService.logout();
        }

        const errorMessage = res.error?.message || 'Server error';

        this.toasterService.error(errorMessage);

        return throwError(res);
    }
}
