<!--Header-->
<app-header></app-header>
<!--Content-->
<app-legal-firm-control-bar [refreshSettingsEvent]="refreshSettingsEvent"
                            [status]="integrationStatus"
></app-legal-firm-control-bar>
<div class="app-content">
    <mat-tab-group dynamicHeight mat-align-tabs="center" mat-stretch-tabs="false">
        <mat-tab label="{{TabIdentifier.LEGAL_FIRM_INFO}}">
            <app-legal-firm-info [refreshSettingsEvent]="refreshSettingsEvent"></app-legal-firm-info>
        </mat-tab>
        <mat-tab label="{{TabIdentifier.ARC_INFORMATION}}">
            <app-arc-info [refreshSettingsEvent]="refreshSettingsEvent"></app-arc-info>
        </mat-tab>
        <mat-tab label="{{TabIdentifier.SETTINGS}}">
            <app-settings-info [refreshSettingsEvent]="refreshSettingsEvent"></app-settings-info>
        </mat-tab>
        <mat-tab label="{{TabIdentifier.ACCOUNTING}}">
            <app-accounting-info [refreshSettingsEvent]="refreshSettingsEvent"></app-accounting-info>
        </mat-tab>
        <mat-tab label="{{TabIdentifier.INTEGRATION}}">
            <app-integration-info [refreshSettingsEvent]="refreshSettingsEvent"></app-integration-info>
        </mat-tab>
    </mat-tab-group>
</div>
