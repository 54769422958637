import {Component, EventEmitter, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {SettingMetadataService} from '../../../core/services/setting-metadata/setting-metadata.service';
import {FirmDetails} from '../../../core/models/firm-details';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {LegalFirmService} from '../../../core/services/legal-firm/legal-firm.service';
import {SettingService} from "../../../core/services/setting/setting.service";
import {SettingDTO} from "../../../core/dto/setting-dto";


@Component({
    selector: 'app-new-legal-firm-popup',
    templateUrl: './new-legal-firm-popup.component.html',
    styleUrls: ['./new-legal-firm-popup.component.css']
})
export class NewLegalFirmPopupComponent implements OnInit {

    newFirmCreatedEvent = new EventEmitter<FirmDetails>();
    submitted = false;
    form: UntypedFormGroup;

    constructor(private dialogRef: MatDialogRef<NewLegalFirmPopupComponent>,
                private settingMetadataService: SettingMetadataService,
                private legalFirmService: LegalFirmService,
                private settingService: SettingService,
                private formBuilder: UntypedFormBuilder) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            legalFirmId: ['', Validators.required],
            companyName: ['', Validators.required]
        });
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.valid) {
            this.legalFirmService.createLegalFirm(
                this.form.controls.legalFirmId.value,
                this.form.controls.companyName.value,
            )
                .subscribe((createdLegalFirm) => {
                    this.setCreatedFirmIntegrationStateAndStatus(createdLegalFirm);
                    this.newFirmCreatedEvent.emit(createdLegalFirm);
                    this.dialogRef.close();
                });
        }
    }

    // todo tests
    setCreatedFirmIntegrationStateAndStatus(createdLegalFirm: FirmDetails) {
        let settingDTO = new SettingDTO(createdLegalFirm.legalFirmId, "integration_state", "UNDEPLOY");
        this.settingService.saveNewSetting(settingDTO).subscribe();
        settingDTO = new SettingDTO(createdLegalFirm.legalFirmId, "integration_status", "UNDEPLOYED");
        this.settingService.saveNewSetting(settingDTO).subscribe();
    }
}
