import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing.module';
import {CoreModule} from './core/core.module';
import {SettingMetadataService} from './core/services/setting-metadata/setting-metadata.service';
import {HttpClientModule} from '@angular/common/http';
import {SharedModule} from './shared/shared.module';
import {LoginCallbackComponent} from './login-callback/login-callback.component';
import {AppConfigService} from './core/services/appconfig/appconfig.service';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ErrorComponent} from './home/tab/base-tab/error.component';

export function initConfig(appConfig: AppConfigService) {
    return () => {
        return appConfig.loadConfig();
    };
}

export function initMetadata(settingMetadataService: SettingMetadataService) {
    return () => {
        return settingMetadataService.loadMetadata();
    };
}

@NgModule({
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        CoreModule,
        MatSnackBarModule
    ],
    declarations: [AppComponent, LoginCallbackComponent, ErrorComponent],
    bootstrap: [AppComponent],
    providers: [
        SettingMetadataService,
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [AppConfigService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initMetadata,
            deps: [SettingMetadataService],
            multi: true,
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {subscriptSizing: 'dynamic'}
        }
    ]
})
export class AppModule {
}
