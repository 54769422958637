import {Component, OnInit, ViewChild} from '@angular/core';
import {SettingMetadataService} from '../../core/services/setting-metadata/setting-metadata.service';
import {Setting} from '../../core/models/setting';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {MetadataPopupComponent} from '../metadata-popup/metadata-popup.component';
import {IntegrationIdentifier} from '../../core/enums/integration-identifier';

@Component({
    selector: 'app-metadata-configs',
    templateUrl: './metadata-configs.component.html',
    styleUrls: ['./metadata-configs.component.css']
})
export class MetadataConfigsComponent implements OnInit {

    displayedColumns = [
        'active', 'name', 'identifier',
        'dataType', 'integrationId', 'tabId', 'star'
    ];

    IntegrationIdentifier = IntegrationIdentifier;

    @ViewChild(MatSort, {static: true})
    sort: MatSort;

    dataSource: MatTableDataSource<Setting> = new MatTableDataSource();

    constructor(private dialog: MatDialog,
                private settingMetadataService: SettingMetadataService) {
    }

    ngOnInit(): void {
        //todo impl
        this.dataSource.sortingDataAccessor = this.getSortingDataAccessor();
        this.fetchMetadata();
    }

    getSortingDataAccessor(): (data: Setting, sortHeaderId: string) => string {
        return (setting: Setting, columnId) => {
            switch (columnId) {
                case 'integrationId':
                    return setting.displayData?.displayIdentifiers?.integrationIds
                        ? IntegrationIdentifier[setting.displayData.displayIdentifiers.integrationIds[0]]
                        : '';
                case 'tabId':
                    return setting.displayData?.displayIdentifiers
                        ? setting.displayData.displayIdentifiers.tabId
                        : '';
                default:
                    return setting[columnId];
            }
        }
    }

    openDialog(data: Setting = new Setting()) {
        const dialogRef = this.dialog.open(MetadataPopupComponent, {
            width: '60%',
            data: data
        });

        dialogRef.componentInstance.dataSaved.subscribe(() => {
            this.fetchMetadata();
        })
    }

    fetchMetadata(): void {
        this.dataSource.data = this.settingMetadataService.getAllMetadata();
        this.dataSource.sort = this.sort;
    }

    getIntegrationsAsString(integrationIdentifiers: string[]): string {
        return integrationIdentifiers ? integrationIdentifiers.map((id) => IntegrationIdentifier[id]).join() : '';
    }
}
