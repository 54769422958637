import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SettingDTO} from '../../dto/setting-dto';
import {map} from 'rxjs/operators';
import {FirmDetails} from '../../models/firm-details';
import {SettingService} from '../setting/setting.service';
import {SettingDTOMapper} from '../../mapper/setting-dto-mapper';
import {HttpWrapperService} from '../http/http-wrapper.service';


@Injectable({
    providedIn: 'root'
})
export class LegalFirmService {

    public legalFirmChangedEvent: EventEmitter<FirmDetails> = new EventEmitter();
    public refreshLegalFirmSettingsEvent: EventEmitter<null> = new EventEmitter();
    public legalFirmId: number;

    constructor(private settingService: SettingService,
                private httpWrapperService: HttpWrapperService) {
    }

    createLegalFirm(legalFirmId: number, companyName: string): Observable<FirmDetails> {
        console.log('New Legal firm data: ' + legalFirmId + ' ' + companyName);
        const settingDTO = new SettingDTO(legalFirmId, 'company_name', companyName);

        return this.settingService.saveNewSetting(settingDTO)
            .pipe(map(() => new FirmDetails(settingDTO)));
    }

    getAllLegalFirms(): Observable<FirmDetails[]> {

        return this.httpWrapperService
            .get('setting?settingId=company_name')
            .pipe(
                map((data: SettingDTO[]) => SettingDTOMapper.toFirmDetailsList(data))
            );
    }

    public setLegalFirm(legalFirm: FirmDetails) {
        this.legalFirmId = legalFirm.legalFirmId;
        this.legalFirmChangedEvent.emit(legalFirm);
    }
}
