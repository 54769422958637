import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {AppConfigService} from '../appconfig/appconfig.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private idToken: string;
    private readonly idTokenName: string = 'cjwt';
    private readonly jwtHelper: JwtHelperService = new JwtHelperService();

    constructor(private router: Router, private appConfigService: AppConfigService) {
        this.idToken = JSON.parse(localStorage.getItem(this.idTokenName));
    }

    login(idToken: string) {
        this.idToken = idToken;
        localStorage.setItem(this.idTokenName, JSON.stringify(this.idToken));
        this.router.navigate(['/home']);
    }

    logout() {
        localStorage.removeItem(this.idTokenName);
        document.location.href = this.getLogoutUrl();
    }

    verifyAuth() {
        if (!this.isLoggedIn()) {
            window.alert('Your session has expired');
            this.logout();
        }
    }

    isLoggedIn() {
        return this.idToken != null && !this.jwtHelper.isTokenExpired(this.idToken);
    }

    getToken(): string {
        return this.idToken;
    }

    getLoginUrl(): string {
        return `https://${this.appConfigService.getConfig().cognitoDomainName}.auth.${this.appConfigService.getConfig().region}.amazoncognito.com/login?redirect_uri=${this.appConfigService.getConfig().cognitoLoginCallbackUrl}&response_type=token&client_id=${this.appConfigService.getConfig().cognitoClientId}`;
    }

    getLogoutUrl(): string {
        return `https://${this.appConfigService.getConfig().cognitoDomainName}.auth.${this.appConfigService.getConfig().region}.amazoncognito.com/logout?logout_uri=${this.appConfigService.getConfig().cognitoLoginCallbackUrl}&client_id=${this.appConfigService.getConfig().cognitoClientId}`;
    }
}
