import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfigService} from "../appconfig/appconfig.service";
import {AuthHandler} from "../interceptor/auth-handler";

@Injectable({
    providedIn: 'root'
})
export class HttpWrapperService {

    protected readonly httpClient: HttpClient;

    constructor(private appConfigService: AppConfigService, private auth: AuthHandler) {
        this.httpClient = new HttpClient(auth);
    }

    get<T>(uri: string): Observable<T> {
        const url = this.buildUrl(uri);
        return this.httpClient.get<T>(url);
    }

    getString(uri: string): any {
        const url = this.buildUrl(uri);
        return this.httpClient.get<string>(url, {responseType: 'text' as 'json'});
    }

    put<T>(uri: string, body: any, headers?: HttpHeaders): Observable<T> {
        const url = this.buildUrl(uri);
        return this.httpClient.put<T>(url, body, {headers: headers});
    }

    private buildUrl(uri: string): string {
        return this.appConfigService.getConfig().apiBaseUrl + uri;
    }
}