<div class="toolbar-container">
    <mat-toolbar-row>
        <mat-form-field id="legal-firm-select-box" appearance="outline">
            <mat-label>{{firmLoading ? 'Loading...' : 'Select Legal Firm'}}</mat-label>
            <mat-select [(value)]="selectedLegalFirm"
                        (selectionChange)="onSelectionChange($event)" disableOptionCentering>
                <mat-option>
                    <ngx-mat-select-search [(ngModel)]="searchText" [noEntriesFoundLabel]="'Not found'"
                                           [placeholderLabel]="'Select Legal Firm...'"
                                           name="search"></ngx-mat-select-search>
                </mat-option>
                <mat-option
                        *ngFor="let firm of legalFirms | searchFirmDetailsFilterPipe:searchText"
                        [value]="firm">
                    {{firm.legalFirmId + " - " + firm.companyName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button id="addNewLegalFirmButton" (click)="openAddNewLegalFirmDialog()" color="primary" mat-raised-button
                style="margin-left: 1%">Add new legal firm
        </button>
        <button id="statusButton"
                *ngIf="showStatusButton()"
                (click)="openStatusDialog()"
                class="status-popup-button"
                color="primary"
                mat-raised-button>{{status}}
        </button>
        <button id="refreshStatusButton"
                *ngIf="showStatusButton()"
                (click)="refreshStatus()" mat-button>
            <span class="material-icons">loop</span>
        </button>
        <span class="spacer"></span>
        <button id="settingsButton" *ngIf="showSettingsButton()" color="primary" mat-mini-fab routerLink="/configs">
            <mat-icon>settings</mat-icon>
        </button>
    </mat-toolbar-row>
</div>