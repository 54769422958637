import {Component, Input} from '@angular/core';
import {Setting} from '../../core/models/setting';
import {InfoIconPopupComponent} from '../popup/info-icon-popup/info-icon-popup.component';
import {MatDialog} from '@angular/material/dialog';
import {UntypedFormGroup} from '@angular/forms';
import {SettingDataType} from 'app/core/enums/setting-data-type';

@Component({
    selector: 'app-info-item',
    templateUrl: './info-item.component.html',
    styleUrls: ['./info-item.component.scss']
})
export class InfoItemComponent {

    @Input('setting')
    setting: Setting;
    @Input('settingsArrayFormGroup')
    settingsArrayFormGroup: UntypedFormGroup;
    SettingDataType = SettingDataType;
    closed: any;

    constructor(private dialog: MatDialog) {
    }

    openDialog(setting: Setting): void {
        const dialogRef = this.dialog.open(InfoIconPopupComponent, {
            width: '500px',
            data: {
                identifier: setting.identifier,
                description: setting.tooltipDescription,
                location: setting.tooltipLocation,
                settingName: setting.name
            }
        });

        dialogRef
            .afterClosed()
            .subscribe(formData => {
                this.closed = formData;
            });
    }

    trimTextArrayInput(event, setting: Setting) {
        if (setting.dataType === SettingDataType.TEXT_ARRAY) {
            const inputValue = event.target.value.trim()
                .replace(/,\s+/g, ',')
                .replace(/\s+,/g, ',');
            this.settingsArrayFormGroup.controls[setting.name].setValue(inputValue);
        }
    }

    togglePasswordVisibility(setting: Setting) {
        setting.showPassword = !setting.showPassword;
    }
}
