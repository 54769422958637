import {DisplayData} from './display-data';
import {SettingDataType} from '../enums/setting-data-type';

export class Setting {

    name: string;
    value?: any;
    identifier: string;
    active: boolean;
    required: boolean;
    dataType: SettingDataType;
    minLength: bigint;
    maxLength: bigint;
    minRows: number;
    pattern: string;
    validationMessage: string;
    displayData: DisplayData;
    tooltipDescription: string;
    tooltipLocation: string;
    default?: string;
    section?: string;
    showPassword: boolean;
    invalid: boolean;

    constructor(name?: string, settingId?: string, dataType?: SettingDataType) {
        this.name = name;
        this.identifier = settingId;
        this.dataType = dataType ? dataType : SettingDataType.INPUT;
        this.active = false;
        this.required = false;
    }
}
