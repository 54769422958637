import {Injectable} from '@angular/core';
import {Setting} from '../../models/setting';
import {Observable, of} from 'rxjs';
import {SettingMetadata} from '../../models/setting-metadata';
import {HttpWrapperService} from '../http/http-wrapper.service';
import {HttpClient} from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class SettingMetadataService {

    private config: Setting[];
    private loaded = false;

    constructor(private httpWrapperService: HttpWrapperService, private httpClient: HttpClient) {
    }

    loadMetadata(): Promise<void> {
        return this.httpClient
            .get<Setting[]>('/assets/metadata.config.json')
            .toPromise()
            .then(data => {
                this.config = data;
                this.loaded = true;
            });
    }

    getAllMetadata(): Setting[] {
        return this.config;
    }

    saveSettingMetadata(settingMetadata: SettingMetadata): Observable<SettingMetadata> {
        console.log(settingMetadata)
        // todo cleanup
        return of()
        // const body = SettingMetadataDto.of(settingMetadata);
        // return this.httpWrapperService.put<SettingDTO>(`meta/${settingMetadata.settingIdentifier}`, body)
        //     .pipe(
        //         map(() => settingMetadata));
    }

}
